<template>
   <div class="wrapper">
     <headBack>
       <template v-slot:title>
         <div class='title'>
            我的订单
         </div>
       </template>
     </headBack>
     <div class='header_cont'></div>
     <div>
       <van-tabs
           v-model:active="activeName"
           @click="clickTab()"
           sticky
       >
         <van-tab
             v-for="(m,i) in navList"
             :title="m.txt"
             :name="m.state"
         >
           <div class="main_box">

             <div class="list_box"
                  v-for="(item,index) in allStatusData[activeName]"
             >
               <div class="top">
                 <div>快天使医护到家商城</div>
                 <div class="timer">{{setStatus(item.orderStatus)}}</div>
               </div>
               <van-card
                   v-for="(val,i) in item.orderData"
                   :num="val.goodsNum"
                   :price="val.payPrice"
                   desc=""
                   :title="val.goodsName"
                   :thumb="val.iconUrl"
                   @click="toOrderDetail(item)"
               />

               <div class="total_box">
                  <div>总价：<span class="jg">¥{{item.payPriceSum}}</span></div>
               </div>
               <div class="btn_box">
                 <button
                     v-for="(t,x) in setBtnStatusTxt(item.orderStatus)"
                     :class="{red:(t.idx == 5 || t.idx == 1)}"
                     @click="toOperation(t,item)"
                 >{{t.name}}</button>

<!--                 <button class="red">再次购买</button>-->
               </div>

             </div>
             <div class="no_box"
                 v-if="noList[activeName]"
             >
               <img src="https://static.yihu365.cn/img/h5Img/shop/no_order.png" alt="">
               <div>暂无相关订单噢~</div>
             </div>
           </div>

         </van-tab>
       </van-tabs>
     </div>
   </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed, reactive, watch} from "vue"
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {Dialog,Toast} from 'vant'
import shopApi from '@axios/shop'
import loginApi from '@axios/login'
export default {
  name: "shopOrder",
  setup() {
    let router = useRouter();
    const store = useStore();
    const route = useRoute();
    const allStatusData = ref([])//所有数据
    const activeName = ref('4');
    const noMessage = ref(true);
    //定义顶部栏目
    const navList = ref([
      {
        state:4,
        txt:'全部'
      },
      {
        state:0,
        txt:'待付款'
      },
      {
        state:1,
        txt:'待发货'
      },
      {
        state:2,
        txt:'已完成'
      },
      {
        state:3,
        txt:'已取消'
      },
      {
        state:5,
        txt:'待收货'
      },

    ])
    //定义配置文件
    /*
    *   orderStatus :
    *     0 -  待付款
    *     1 -  待收货
    *     2 -  已完成
    *     3 -  已取消
    * */
    const Status = ref([
      {
        state:0,
        txt:'待付款'
      },
      {
        state:1,
        txt:'待发货'
      },
      {
        state:2,
        txt:'已完成'
      },
      {
        state:3,
        txt:'已取消'
      },
      {
        state:4,
        txt:'全部'
      },
      {
        state:5,
        txt:'待收货'
      }
    ])

    const setStatus = (s) => {
       return Status.value.find(item => item.state == s).txt
    }
    //按钮
    const btnList = ref([
      {
        name:'取消订单',
        orderStatus:[0,1],
        idx:0
      },
      {
        name:'立即支付',
        orderStatus:[0],
        idx:1
      },
      {
        name:'查看物流',
        orderStatus:[2,5],
        idx:2
      },
      {
        name:'退换/售后',
        orderStatus:[2],
        idx:3
      },
      {
        name:'评论晒单',
        orderStatus:[2],
        evaluationStatus:0,//未评论
        idx:4
      },
      {
        name:'再次购买',
        orderStatus:[2,1,3,5],
        idx:5
      },
      // {
      //   name:'已评论', //前提是完成订单
      //   orderStatus:[2],
      //   evaluationStatus:1,//已经评论
      //   idx:6
      // },
    ])
   const setBtnStatusTxt = (status) => {//根据订单状态设置不同的按钮
       let btnArr = [];
     btnList.value.forEach((item,index)=> {
         item.orderStatus.map((i)=> {
              if(i == status){
                btnArr.push(item)
              }

         })
     })
      return btnArr;
   }


   //标签切换 请求数据
    const orderList = ref('')
    const noList = ref([])
    const getOrderList = (status) => {
      const params ={
        userId : store.state.uid,
        orderStatus: status,
        pageNum: 1,
        pageSize:20
      }
      // if(allStatusData.value[status]){
      //    return;
      // }
      shopApi.getOrderList(params).then((res)=> {
        allStatusData.value[status] = res.data.pageData;
        if(res.data.pageData.length == 0){
          noList.value[status] = true;
        }else{
          noList.value[status] = false;
        }
        console.log(noList.value)
      })
    }
    const clickTab = (index) => {
      getOrderList(activeName.value)

    }
    clickTab();


    //取消订单
    const orderCancel  = (id)=> {
      const params = {
          orderId:id,
          userId:store.state.uid
      }
      shopApi.getOrderCancel(params).then((res)=> {
        console.log(res);
        if(res.code == '0000'){
            Toast('您已取消订单')
        }
      })
    }

    //支付
    //支付
    const payH5 = (orderId,orderPriceSum) => {
      let formData = new FormData();
      let redirectUrl = encodeURIComponent("https://mi.yihu365.cn/shop/shopOrder")
      formData.append('data',JSON.stringify({
        function : 'rechargeOnline',
        source : 'h5_users_002',
        user_id : store.state.uid,
        fee : orderPriceSum + '',
        pay_type : '261',
        voucherId : "",
        // order_id : route.query.orderId,
        orderId : orderId,
        gate_id : '1',
        app_flag : '3',
        device_id : 'h5',
        h5PayReturnUrl:redirectUrl,
        order_type:'30',
        _from : 'h5',
        token : store.state.token,
        userid : store.state.uid,
        userId : store.state.uid,
        version : store.state.version,
        newversion : store.state.newversion,
        sid : store.state.sid,

      }))
      formData.append('encryption',false)
      loginApi.loginAction(formData).then((res)=>{
        console.log(res.data)
        window.location.href = res.data;
      })
    }
    const payH5Wx = (orderId,orderPriceSum) => {//微信环境支付
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'rechargeOnline',
        source : 'h5_users_wx_101',
        user_id : store.state.uid,
        fee : orderPriceSum+'',
        pay_type : '45',
        gate_id : '-1',
        voucherId : "",
        sid : store.state.sid,
        app_flag : '3',
        device_id : 'h5',
        orderId : orderId,
        _from : 'h5',
        token : store.state.token,
        userid : store.state.uid,
        userId : store.state.uid,
        version : store.state.version,
        newversion : store.state.newversion,
        openid : store.state.openId,
        order_type:'30',
      }))

      formData.append('encryption',false)
      loginApi.loginAction(formData).then((res)=>{
        let result = JSON.parse(res.data)
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
              "appId":result.appId,     //公众号ID，由商户传入
              "timeStamp":result.timeStamp,         //时间戳，自1970年以来的秒数
              "nonceStr":result.nonceStr, //随机串
              "package":result.package,
              "signType":result.signType,         //微信签名方式：
              "paySign":result.paySign //微信签名
            },
            function(res){
              if(res.err_msg == "get_brand_wcpay_request:ok" ){
                router.push({
                  name : 'shopOrder'
                })
              }
              if(res.err_msg == "get_brand_wcpay_request:cancel" ){
                router.push({
                  name : 'shopOrder'
                })
              }
              if(res.err_msg == "get_brand_wcpay_request:fail" ){
                router.push({
                  name : 'shopOrder'
                })
              }
            }
        );
      })
    }

    const isWx = () =>{
      let ua = window.navigator.userAgent.toLowerCase();
      return (ua.match(/MicroMessenger/i) == 'micromessenger') ? true : false;
    }
    const chosePayment = (orderId,orderPriceSum) => {
      if(isWx()){//微信浏览器
        //小程序环境判断
        wx.miniProgram.getEnv((res)=>{
          if(res.miniprogram){

            // alert("在小程序里iii")
            console.log('小程序里')

          }else{
            payH5Wx(orderId,orderPriceSum);//微信公众号支付
          }
        })

      }else{//非微信浏览器
        payH5(orderId,orderPriceSum)
      }
    }
    //计算订单总商品数量
    const getShopNums = (data)=> {
      let nums = 0;
       data.forEach((item)=> {
          nums += item.goodsNum
       })
      return nums
    }
  //底部按钮
    const toOperation =(t,item) => {
        console.log(item)
      if(t.idx == '0'){ //取消订单按钮
        orderCancel(item.orderId);
        return;
      }
      if(t.idx == '5'){ //再次购买按钮
        console.log(item)
         //创建订单
        const obj = {
          'allNums':getShopNums(item.orderData),
          'total':item.orderPriceSum,
          'list':item.orderData
        }
        sessionStorage.setItem('orderData',JSON.stringify(obj));
        router.push({
          name : 'order'
        })
        return;
      }
      if(t.idx == '1'){ //立即支付按钮
        chosePayment(item.orderId,item.orderPriceSum)
        return;
      }
      if(t.idx =='2'){//物流
        router.push({
          name:'logistics',
          query:{
            orderId :item.orderId
          }
        })
        Toast("查看物流")
        return;
      }
      if(t.idx =='3'){//售后
        console.log(item)
         const orderData = JSON.stringify(item.orderData)
        router.push({
          name:'afterSale',
          query:{
            orderId :item.orderId,
            orderData:orderData
          }
        })
        Toast("售后")
        return;
      }
      if(t.idx =='4' && item.evaluationStatus == '0'){//评价晒单
        router.push({
          name:'evaluateEdit',
          query:{
            orderId :item.orderId
          }
        })
      }else{
        Toast("您已经评价该订单了哦")
      }
    }

    //跳转订单详情
    const toOrderDetail = (item)=> {

      router.push({
         name:'shopOrderDetail',
         query:{
           orderId:item.orderId
         }
      })
    }
    return {
      getOrderList,
      orderList,
      Status,
      btnList,
      setStatus,
      setBtnStatusTxt,
      toOperation,
      navList,
      clickTab,
      activeName,
      allStatusData,
      noList,
      noMessage,
      orderCancel,
      toOrderDetail
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  background: #f2f4f5;
  min-height: 100vh;
}

::v-deep(.van-tab--active){
    color:#00c291;
    font-size: 32px;
}
::v-deep(.van-card__title){
    font-size: 26px;
}
  ::v-deep(.van-card__price-integer){
    font-size: 34px;
  }
   ::v-deep(.van-tabs__line){
     background: #00c291;
   }
   .main_box{
     padding-top: 30px;
     padding-bottom: 60px;
   }
   .no_box{
     padding-bottom: 20px;
     text-align: center;
     font-size: 28px;
     color:#999999;
      img{
        width: 300px;
      }
   }
   .list_box{
     color:#323232;
     width: 94%;
     margin:0 auto 20px;
     background: #FFFFFF;
     border-radius: 20px;
     padding:20px 0px;
     .top{
       font-size: 30px;
       padding:0 30px;
       margin-bottom: 10px;
       font-weight: 500;
       display: flex;
       justify-content: space-between;
       .timer{
         color:#999;
         font-size: 24px;
       }
     }
     ::v-deep(.van-card){
       //border-radius: 20px;
       background: #FFFFFF;
     }
     ::v-deep(.van-button){
       border-radius: 8px;
       font-size: 24px;
     }
     ::v-deep(.van-card:not(:first-child)){
       margin-top: 0;
     }
     ::v-deep(.van-card:first-child){
       //border-radius: 20px 20px 0 0;
     }
     ::v-deep(.van-card:last-child){
       //border-radius:0 0 20px 20px ;
     }
     .total_box{
       margin:10px 0;
       padding-right: 30px;
       text-align: right;
       font-size: 26px;
       .jg{
         font-size: 32px;
       }
     }
     .btn_box{
       margin-top: 16px;
       display: flex;
       justify-content: flex-end;
       padding-right: 20px;
       button{
         border:1px solid #ccc;
         margin-left:10px;
         background: #FFFFFF;
         font-size: 28px;
         border-radius: 26px;
         padding:4px 18px;
       }
       .red{
         color:#00c291;
         border:1px solid #00c291;
       }
     }
   }
   ::v-deep(.van-count-down){
     width:110px;
     margin-left: 10px;
     color:#ee0a24;
     font-size: 24px;
   }
</style>
